<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 

    
        <transition name="zoom-fade" mode="out-in">
            <div v-if="is_confirm">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>
            </div>
            <div v-else>

                <div >
                    <p class="text-muted" style="font-size: 13px; line-height: 20px;">Выберите статью обработки заявки на вывод.</p>
                </div>
                
                <b-form-group >
                    <validation-provider #default="{ errors }" name="type" rules="required" >
                        <v-select
                          :options="clauses"
                          v-model="clause"
                          label="name"
                          :clearable="false"
                          placeholder="Выбрать статью закрытия"
                        />              
                        <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>  

                <b-form-group v-if="clause && clause.value === 100">
                    <validation-provider #default="{ errors }" name="type" rules="required" >
                       <span class="text-muted" style="font-size: 12px;">Статья закрытия заявки</span>
                           
                            <b-form-textarea  placeholder="Укажите причину закрытия заявки" v-model="clause_other" />
                            <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>   

                <b-button @click="close" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                    <div v-if="is_loading" class="d-flex justify-content-center">
                        <span class="mt-auto mb-auto ml-1">Отправка...</span>
                    </div>
                    <span v-else>
                        Подтвердить
                    </span>

                </b-button>
            </div>
        </transition>
    </b-overlay>
</template>

<script>
    import * as SuccessAnimeView from "@/assets/anime/success.json"
        
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, integer } from '@validations'
    
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                User,

                is_loading: false,
                is_confirm: false,

                clause: null,
                clause_other: '',

                clauses: [
                    { name: "Создан контракт на сумму вывода", value: 1 },
                    { name: "Иное", value: 100 },
                ],

                animationData: { 
                    animationData: SuccessAnimeView.default,
                    loop: false
                }, 
            }
        },
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            close() {
                this.error = false
                this.is_loading = true;

                this.$request.post("withdrawal/close", {
                    clause: this.clause,
                    clause_other: this.clause_other,
                    withdrawal:  this.data.id,
                } ).then( result => {
                    this.is_loading = false;
                    this.is_confirm = true;

                    this.$emit('afterGet');
                }).catch(err => {
                    this.is_loading = false;
                });
            }
        },
        computed: {
            isCanSend() {
                if( !this.is_hash_valide ) return false;
                
                return true;
                
            },
        },
        components: {
            vSelect,
            ValidationProvider,
            ValidationObserver,
        },
        watch: {

        },
        mounted() {
       
        }

    }

</script>