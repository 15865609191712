<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                    Заявки на вывод средств
                </b-card-title>

                <b-card-title>
                    <b-badge :variant="selectedBadges.created ? 'light-danger' : ''" style="margin:0px 5px; cursor: pointer" @click="selectedBadges.created = !selectedBadges.created">
                        Не обработаны
                    </b-badge>
                    <b-badge :variant="selectedBadges.createdPayments ? 'light-danger' : ''" style="margin:0px 5px; cursor: pointer" @click="selectedBadges.createdPayments = !selectedBadges.createdPayments"> 
                        Оплачены не полностью
                    </b-badge>
                    <b-badge :variant="selectedBadges.awaiting ? 'light-primary' : ''" style="margin:0px 5px; cursor: pointer" @click="selectedBadges.awaiting = !selectedBadges.awaiting">
                        Ожидают подтверждения сети
                    </b-badge>
                    <b-badge :variant="selectedBadges.confirmed ? 'light-success' : ''" style="margin:0px 5px; cursor: pointer" @click="selectedBadges.confirmed = !selectedBadges.confirmed">
                        Оплачены
                    </b-badge>
                    <b-badge :variant="selectedBadges.processed ? 'light-success' : ''" style="margin:0px 5px; cursor: pointer" @click="selectedBadges.processed = !selectedBadges.processed">
                        Обработаны
                    </b-badge>
                </b-card-title>

            </b-card-header>
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='7' :rows='5'>
                
            </b-skeleton-table>
            <b-table-simple v-else responsive>
                <b-thead>
                    <b-tr>
                        <b-th class="text-nowrap">Пользователь</b-th>
                        <b-th class="text-nowrap">Адрес отправки</b-th>
                        <b-th class="text-nowrap">Приоритет</b-th>
                        <b-th class="text-nowrap">Сумма</b-th>
                        <b-th class="text-nowrap">Комиссия</b-th>
                        <b-th class="text-nowrap">ID транзакции</b-th>
                        <b-th class="text-nowrap">Статус</b-th>
                        <b-th class="text-nowrap"></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(wr, index) in dataSource.items" :key="index" class=" font-weight-normal">
                        <b-th class="font-weight-normal text-nowrap cursor-pointer" @click="$router.push({ name: 'admin-users-view', params: { id: wr.user.id }})">
                            <div class="d-flex">
                                <b-avatar
                                    :variant="Users.utils.resolveAvatarColor( wr.user.id )"
                                    :text="Users.utils.capitalize(`${wr.user.firstName}`, 1 )"
                                />
                                <div class="d-flex flex-column" style="margin-left: 12px;">
                                    <span class="font-weight-bolder text-primary text-capitalize m-0">
                                        {{ wr.user.firstName }} {{ wr.user.lastName }}
                                    </span>
                                    <span class="text-muted" style="font-size: 11px;">#{{ wr.user.id }}</span>
                                </div>
                            </div>
                        </b-th>
                        <b-th class="font-weight-normal" >
                            <b-link :href="`https://www.blockchain.com/btc/address/${wr.transactions[0].address}`" target="_blank">
                                <span>{{ start_and_end(wr.transactions[0].address) }} </span>
                            </b-link>
                        </b-th>
                        <b-th class="font-weight-normal" >{{ priorites[wr.priority] }}</b-th>
                        <b-th class="font-weight-normal" >{{ wr.btc }} BTC</b-th>
                        <b-th class="font-weight-normal" >{{ wr.transactions[0].fee * -1}} BTC</b-th>
                        <b-th class="font-weight-normal" > 
                            <div v-for="(t, i) in wr.transactions" :key="i">
                                <b-link v-if="t.txid"  :href="`https://www.blockchain.com/btc/tx/${t.txid}`" target="_blank">
                                    <span>{{ start_and_end(t.txid) }} </span>
                                </b-link> 
                            </div>
                            <b-button v-if="wr.status == 0 && (wr.unconfirmed + wr.received) < wr.btc" @click="addTxid(index)" pill variant="primary" class="">Подтвердить</b-button>
                        </b-th>
                        <b-th >
                            <b-badge v-if="wr.status == 0 && wr.unconfirmed == 0 && wr.received == 0" variant="light-danger">
                                Не обработано
                            </b-badge>
                            <b-badge v-if="wr.status == 0 && (wr.unconfirmed > 0 || wr.received > 0)" variant="light-danger">
                                Оплачен не полностью. <br> Осталось доплатить {{ wr.btc - (wr.unconfirmed + wr.received) }} BTC
                            </b-badge>
                            <b-badge v-else-if="wr.status == 1" variant="light-primary">
                                Ожидает подтверждения сети
                            </b-badge>
                            <b-badge v-else-if="wr.status == 2" variant="light-success">
                                Оплачено
                            </b-badge>
                            <b-badge v-else-if="wr.status == 3" variant="light-success">
                                Обработано
                            </b-badge>
                        </b-th>
                         <b-th >
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                                style=""
                                class="group-picker"
                                v-if="wr.status == 0"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreHorizontalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item  @click="close(index)" >
                                    Закрыть заявку
                                </b-dropdown-item>
                                <!-- <b-dropdown-item @click="$emit('onEdit')" >
                                    Редактировать
                                </b-dropdown-item> -->
                                <!-- <b-dropdown-divider></b-dropdown-divider> -->
                                <!-- <b-dropdown-item > -->
                                <!-- Заблокировать -->
                                <!-- </b-dropdown-item> -->
                            </b-dropdown>
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>

        <b-modal id="confirm-modal" v-model="modalsVisibility.confirm" title="Подтверждение вывода средств" centered hide-footer >
            <confirm-modal-content :data="dataSource.items[selectedIndex]" @afterGet="get"/>
        </b-modal>

        <b-modal id="closed-modal" v-model="modalsVisibility.closed" title="Обработка вывода средств" centered hide-footer >
            <closed-modal-content :data="dataSource.items[selectedIndex]" @afterGet="get"/>
        </b-modal>

    </div>
</template>

<script>
    import Users from "@/modules/admin/users/"
    import ConfirmModalContent from "./modals/withdrawal/confirm"
    import ClosedModalContent from "./modals/withdrawal/closed"

    export default {

        data() {
            return {
                Users,
                is_loading: false,
                dataSource: {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                },   
                priorites: {
                    'low' : 'Низкий',
                    'medium' : 'Средний',
                    'height' : 'Высокий',
                },
                modalsVisibility: {
                    confirm: false,
                    closed: false
                },

                selectedIndex: null,

                selectedBadges: {
                    created: true,
                    createdPayments: true,
                    awaiting: false,
                    confirmed: false,
                    processed: false
                }
            }
        },
        methods: {

            get() {
                this.is_loading = true;
                this.$request.get("withdrawal/list", {
                    page: this.$route.query.page,
                    selected: this.selectedBadges
                }).then( rsp => {
                    Object.keys( this.dataSource ).forEach( key => {
                        if( rsp.hasOwnProperty(key) ) {
                            this.dataSource[key] = rsp[key];
                        }
                    });
                    this.is_loading = false;
                });

            },

            addTxid(index) {
                this.selectedIndex = index;

                this.showModal('confirm');
            },

            closeModal( action ) {
                    
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                    
            },
                
            showModal( action ) {
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },

            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },

            close( index ) {
                this.selectedIndex = index;

                this.showModal('closed');
            }
        },
        components: {
          ConfirmModalContent,
          ClosedModalContent
        },
        watch: {
            selectedBadges: {
                deep: true,
                handler(bef) {
                    this.get();
                }
            }
        },
        mounted() {
            this.get();
        }
    }

</script>