<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <transition name="zoom-fade" mode="out-in">
            <div v-if="is_confirm">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>
            </div>
            <div v-else>
                
                <div >
                    <p class="text-muted" style="font-size: 13px; line-height: 20px;">Введенный хэш будет автоматически проверен на валидность с выбранной заявкой.</p>
                    <p class="text-muted" style="font-size: 13px; line-height: 20px;">Статус заявки будет автоматически изменен, в соответсивии со статусом транзакции.</p>
                </div>
                
                <div class="data-form">
                    <b-form-group>
                        <span class="text-muted" style="font-size: 12px;">Пользователь #{{ data.user.id }}</span>
                        <div class="d-flex flex-column">
                            <span class=" text-capitalize m-0">
                                {{ data.user.firstName }} {{ data.user.lastName }}
                            </span>
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <span class="text-muted" style="font-size: 12px;">Оплата произведена по адресу</span>
                        <p class="m-0">{{ data.transactions[0].address }}</p>
                    </b-form-group>
                    <b-form-group>
                        <div class="d-flex justify-content-between">
                            <div style="flex-grow: 1;">
                                <span class="text-muted" style="font-size: 12px;">Хэш транзакции</span>
                                <b-form-input v-model="txid" placeholder="" @input="checkHashValide"/>
                            </div>
                        </div>
                    </b-form-group>
                </div>

                <b-alert :show="!is_hash_valide" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                    В транзакции не фигурирует адрес получения, либо этой транзакцией уже подтверждали заявку на вывод.<br> <strong>Проверьте введенный хэш транзакции.</strong>
                </b-alert>

                <b-button @click="confirm" :disabled="!isCanSend" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                    <div v-if="is_loading" class="d-flex justify-content-center">
                        <span class="mt-auto mb-auto ml-1">Отправка...</span>
                    </div>
                    <span v-else>
                        Подтвердить
                    </span>

                </b-button>
            </div>
        </transition>
    </b-overlay>
</template>

<script>
    import * as SuccessAnimeView from "@/assets/anime/success.json"
    
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                User,
                txid: null,

                is_loading: false,
                is_hash_valide: false,
                is_confirm: false,

                animationData: { 
                    animationData: SuccessAnimeView.default,
                    loop: false
                }, 
            }
        },
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            confirm() {
                this.error = false
                this.is_loading = true;

                this.$request.post("withdrawal/confirm", this.transaction ).then( result => {
                    this.is_loading = false;
                    this.is_confirm = true;

                    this.$emit('afterGet');
                }).catch(err => {
                    this.is_loading = false;
                });
            }
        },
        computed: {
            isCanSend() {
                if( !this.is_hash_valide ) return false;
                
                return true;
                
            },
          
            checkHashValide() {
                if (this.txid) {
                    this.$request.get("withdrawal/transaction.check", {
                        txid: this.txid,
                        addr: this.data.transactions[0].address
                    }).then( res => {
                        this.is_hash_valide = true
                    }).catch(err => {
                        this.is_hash_valide = false
                    });
                }
            },

            transaction() {

                return {
                    withdrawal : this.data.id,
                    addr: this.data.transactions[0].address,
                    txid: this.txid
                }
                
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {
        //       setTimeout(() => {
        //        this.is_confirm = true
        //    }, 1500 );
        }

    }

</script>